import React, { useState, useContext } from 'react'
import {
  Container,
  Card,
  Stack,
  TextField,
  Button,
  CircularProgress
} from '@mui/material'

import { AuthContext } from '../../context/auth-context'
import { useHttpClient } from '../../hooks/http-hook'

import Link from '../../components/shared/Link'
import FancyTitle from '../../components/shared/FancyTitle'

import './style.scss'

const Authenticate = () => {
  const auth = useContext(AuthContext)
  const [isLoginMode, setIsLoginMode] = useState(true)
  const { isLoading, sendRequest } = useHttpClient()
  const [inputData, setInputData] = useState({
    name: undefined,
    email: undefined,
    password: undefined
  })

  const onSubmitHandler = async () => {
    if (isLoginMode) {
      try {
        const response = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + '/users/login',
          'POST',
          JSON.stringify({
            email: inputData.email,
            password: inputData.password
          }),
          {
            'Content-Type': 'application/json'
          }
        )
        auth.login(response.userId, response.token, response.role)
      } catch (err) {
        console.log(err)
      }
    } else {
      try {
        const response = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + '/users/signup',
          'POST',
          JSON.stringify({
            name: inputData.name,
            email: inputData.email,
            password: inputData.password
          }),
          {
            'Content-Type': 'application/json'
          }
        )
        auth.login(response.userId, response.token, response.role)
      } catch (err) {
        console.log(err)
      }
    }
  }

  const switchModeHandler = () => {
    setIsLoginMode((prevMode) => !prevMode)
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      onSubmitHandler()
    }
  }

  return (
    <React.Fragment>
      {/* <ErrorModal error={error} onClear={clearError} /> */}
      <Container maxWidth="sm" sx={{ mt: 5 }} className="login-page">
        <Card className="authentication" sx={{ p: 2 }}>
          <FancyTitle text="CCB" />
          <hr />
          <Stack spacing={2} className="inputs">
            {!isLoginMode && (
              <TextField
                id="name"
                label="Nimi"
                variant="outlined"
                onChange={(e) =>
                  setInputData({
                    ...inputData,
                    name: e.target.value
                  })
                }
              />
            )}
            <TextField
              id="email"
              label="Email"
              variant="outlined"
              onChange={(e) =>
                setInputData({
                  ...inputData,
                  email: e.target.value
                })
              }
              onKeyDown={handleKeyDown}
            />
            <TextField
              id="password"
              label="Salasana"
              variant="outlined"
              type="password"
              onChange={(e) =>
                setInputData({
                  ...inputData,
                  password: e.target.value
                })
              }
              onKeyDown={handleKeyDown}
            />
            <Button type="submit" variant="contained" onClick={onSubmitHandler}>
              {isLoginMode ? 'KIRJAUDU' : 'REKISTERÖIDY'}{' '}
              {isLoading && (
                <CircularProgress
                  sx={{ color: 'white', ml: '10px' }}
                  size="20px"
                />
              )}
            </Button>
          </Stack>
          <div className="footer-text">
            {isLoginMode ? (
              <p>
                Eikö sinulla ole tunnuksia?
                <br />
                Luo tunnukset{' '}
                <Link clickFunction={switchModeHandler} linkText="tästä" />
              </p>
            ) : (
              <p>
                Kirjaudu sisään{' '}
                <Link clickFunction={switchModeHandler} linkText="täältä" />
              </p>
            )}
          </div>
        </Card>
      </Container>
    </React.Fragment>
  )
}

export default Authenticate
