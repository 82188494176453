import React from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material'
import PropTypes from 'prop-types'

FormDialog.propTypes = {
  summary: PropTypes.any,
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  deleteItem: PropTypes.func,
  msg: PropTypes.any
}

export default function FormDialog(props) {
  const { open, handleClose, deleteItem, msg } = props

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>{'Oletko varma?'}</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            {msg}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Peruuta</Button>
          <Button onClick={deleteItem} autoFocus>
            Poista
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
