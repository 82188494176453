import { SaveOutlined } from '@mui/icons-material'
import React, { useEffect, useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { useSnackbar } from 'notistack'
import axios from 'axios'
import {
  Button,
  Grid,
  TextareaAutosize,
  TextField,
  Tooltip
} from '@mui/material'

import { AuthContext } from '../../context/auth-context'

function EditorLyric(props) {
  const auth = useContext(AuthContext)
  const { id } = props
  const { enqueueSnackbar } = useSnackbar()

  let [title, setTitle] = useState('')
  let [lyric, setLyric] = useState('')
  let [artist, setArtist] = useState('')
  let [current_key, setCurrentKey] = useState('')
  let [users_key, setUsersKey] = useState('[]')
  let [category, setCategory] = useState('')
  let [youtube_link, setYoutube] = useState('')

  // Get song
  useEffect(() => {
    const getSong = async () => {
      if (!id) return
      if (Number(id) === Number(0)) return
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/songs/${id}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${auth.token}`
          }
        }
      )
      setTitle(response.data.title)
      setLyric(response.data.lyric)
      setArtist(response.data.artist)
      setCurrentKey(response.data.key_name)
      setUsersKey(response.data.users_key)
      setCategory(response.data.category)
      setYoutube(response.data.youtube_link)
    }
    getSong()
  }, [id])

  // Ssnacks
  const saveSnack = () => {
    enqueueSnackbar('Laulu tallennettu onnistuneesti!', {
      variant: 'success'
    })
  }
  const unauthorizedSnack = () => {
    enqueueSnackbar(
      'Ei oikeutta muokata laulua, voit muokata vain itse lisäämiäsi lauluja',
      {
        variant: 'error'
      }
    )
  }
  const errorSnack = (error) => {
    if (error) {
      enqueueSnackbar(`${error}`, {
        variant: 'error'
      })
    } else {
      enqueueSnackbar(`Tapahtui virhe :(`, {
        variant: 'error'
      })
    }
  }

  // Update old song
  const updateSong = async () => {
    const resp = await axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/songs/update`,
        {
          id: id,
          title: title,
          lyric: lyric,
          artist: artist,
          key_name: current_key,
          users_key: users_key,
          category: category,
          youtube_link: youtube_link
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${auth.token}`
          }
        }
      )
      .catch((error) => {
        errorSnack(error)
      })
    if (!resp) return
    if ('status' in resp.data) {
      if (resp.data.status === 'successfully') {
        saveSnack()
        return
      }
      if (resp.data.status === 'unauthorized') {
        unauthorizedSnack()
        return
      }
    }
    errorSnack('')
  }

  // Add new song
  const addSong = async () => {
    const resp = await axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/songs/add`,
        {
          title: title,
          lyric: lyric,
          artist: artist,
          key_name: current_key,
          category: category,
          youtube_link: youtube_link
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${auth.token}`
          }
        }
      )
      .catch((error) => {
        errorSnack(error)
      })

    if (!resp) return
    if ('status' in resp.data) {
      if (resp.data.status === 'successfully') {
        saveSnack()
        return
      }
    }
    errorSnack('')
  }

  // Save song handler
  const saveSong = () => {
    if (!id) return
    if (Number(id) === Number(0)) {
      addSong()
    } else {
      updateSong()
    }
  }

  return (
    <div>
      <div className="tools">
        <Tooltip title="Tallenna laulu">
          <Button variant="contained" size="small" onClick={saveSong}>
            <SaveOutlined />
          </Button>
        </Tooltip>
      </div>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <TextField
            className="title"
            label="Nimi"
            variant="standard"
            onChange={(e) => setTitle(e.target.value)}
            value={title}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            className="title"
            label="Tyyppi"
            variant="standard"
            onChange={(e) => setCategory(e.target.value)}
            value={category}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            className="key"
            label="Sävellaji"
            variant="standard"
            onChange={(e) => setCurrentKey(e.target.value)}
            value={current_key}
          />
        </Grid>
      </Grid>
      <TextareaAutosize
        aria-label="textarea"
        className="textarea"
        placeholder="Sanat ja soinnut..."
        defaultValue={lyric}
        onChange={(e) => setLyric(e.target.value)}
      />
      <Grid item xs={3}>
        <TextField
          className="youtube"
          label="Youtube link"
          variant="standard"
          onChange={(e) => setYoutube(e.target.value)}
          value={youtube_link}
        />
      </Grid>
      <TextField
        className="artist"
        label="Tekijä(t)"
        variant="standard"
        onChange={(e) => setArtist(e.target.value)}
        value={artist}
      />
    </div>
  )
}

EditorLyric.propTypes = {
  id: PropTypes.string
}

export default EditorLyric
