import { DeleteOutlined, EditOutlined } from '@mui/icons-material'
import React, { useState, useContext } from 'react'
import { Link } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import PropTypes from 'prop-types'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tooltip
} from '@mui/material'
import DeleteDialog from '../../components/DeleteDialog'
import InputDialog from '../../components/InputDialog'
import axios from 'axios'

import { AuthContext } from '../../context/auth-context'

function SongList(props) {
  const auth = useContext(AuthContext)
  const { enqueueSnackbar } = useSnackbar()
  const { songlists, getSonglists } = props
  const [songListId, setSongListId] = useState('')

  // Confirm dialog
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const handleDelete = (e) => {
    setSongListId(e.currentTarget.getAttribute('id'))
    setDeleteDialogOpen(true)
  }
  const deleteDialogClose = () => {
    setDeleteDialogOpen(false)
  }

  // Input dialog
  const [inputDialogOpen, setInputDialogOpen] = useState(false)
  const handleEdit = (e) => {
    setSongListId(e.currentTarget.getAttribute('id'))
    setInputDialogOpen(true)
  }

  const formatDate = (date) => {
    const d = new Date(date)
    return d.toLocaleDateString()
  }

  // Delete snack
  const deleteSnack = () => {
    enqueueSnackbar('Laululista poistettu onnistuneesti!', {
      variant: 'default'
    })
  }
  // Save snack
  const saveSnack = () => {
    enqueueSnackbar('Laululista tallennettu onnistuneesti!', {
      variant: 'success'
    })
  }
  // Error snack
  const errorSnack = (error) => {
    if (error) {
      enqueueSnackbar(`${error}`, {
        variant: 'error'
      })
    } else {
      enqueueSnackbar(`Tapahtui virhe :(`, {
        variant: 'error'
      })
    }
  }

  const deleteSongList = async () => {
    if (!songListId) return
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/songlists/delete`,
      {
        id: songListId
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.token}`
        }
      }
    )
    if ('status' in response.data) {
      if (response.data.status === 'successfully') {
        setDeleteDialogOpen(false)
        deleteSnack()
        getSonglists()
      }
    }
  }

  const updateListName = async (name) => {
    if (songListId) {
      const resp = await axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/songlists/update`,
          {
            id: songListId,
            name: name
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${auth.token}`
            }
          }
        )
        .catch((error) => {
          errorSnack(error)
        })

      if (!resp) return
      if ('status' in resp.data) {
        if (resp.data.status === 'successfully') {
          saveSnack()
          getSonglists()
          return
        }
      }
      errorSnack('')
    }
  }

  const generateBtns = (id) => {
    return (
      <TableCell align="left" id={id}>
        <Tooltip title="Muokkaa laululistaa" id={id}>
          <button className="btn action-btn" onClick={handleEdit} id={id}>
            <EditOutlined className="icon" />
          </button>
        </Tooltip>
        <Tooltip title="Poista laululista" id={id}>
          <button className="btn action-btn" onClick={handleDelete} id={id}>
            <DeleteOutlined className="icon" />
          </button>
        </Tooltip>
      </TableCell>
    )
  }

  const generateRows = () => {
    if (songlists) {
      return songlists.map((row, index) => (
        <TableRow
          className="song-name"
          id={row.id}
          key={index}
          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        >
          <TableCell>{row.id}</TableCell>
          <TableCell className="noselect" component="th" scope="row">
            <Link to={`/songs/${row.id}`}>{row.name}</Link>
          </TableCell>
          {/* <TableCell align="left">{row.name}</TableCell> */}
          <TableCell align="left">{formatDate(row.created_at)}</TableCell>
          {generateBtns(row.id)}
        </TableRow>
      ))
    }
  }

  return (
    <div className="song-list">
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small">
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>Nimi</TableCell>
              <TableCell align="left">Luotu</TableCell>
              <TableCell align="left">Toiminnot</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{generateRows()}</TableBody>
        </Table>
      </TableContainer>
      <DeleteDialog
        open={deleteDialogOpen}
        handleClose={deleteDialogClose}
        deleteItem={deleteSongList}
        msg={'Tämä laululista poistetaan pysyvästi, etkä voi palauttaa sitä!'}
      />
      <InputDialog
        open={inputDialogOpen}
        setOpen={setInputDialogOpen}
        doWithInputValue={updateListName}
      />
    </div>
  )
}

SongList.propTypes = {
  songlists: PropTypes.array,
  getSonglists: PropTypes.func
}

export default SongList
