import React, { useState, useContext, useEffect } from 'react'
import PropTypes from 'prop-types'

import axios from 'axios'
import { useSnackbar } from 'notistack'
import DeleteDialog from '../DeleteDialog'

import { AuthContext } from '../../context/auth-context'
import useWindowDimensions from '../../hooks/useWindowDimensions'

import TableFormat from './Table'
import StackFormat from './Stack'

import './style.scss'

function SongList(props) {
  const auth = useContext(AuthContext)
  const { width } = useWindowDimensions()
  const { enqueueSnackbar } = useSnackbar()
  const { songs, listmode, songlist_id, getSonglist } = props
  const [songId, setSongId] = useState('')

  // Scroll position
  const setScrollPosition = () => {
    const position = window.pageYOffset
    sessionStorage.setItem('scrollPosition', position)
  }

  useEffect(() => {
    if (songs.length) {
      const scrollPosition = sessionStorage.getItem('scrollPosition')
      console.log(scrollPosition)
      if (scrollPosition) {
        window.scrollTo(0, parseInt(scrollPosition))
        sessionStorage.removeItem('scrollPosition')
      }
    }
  }, [songs])

  // Confirm dialog
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const handleDelete = (id) => {
    setSongId(id)
    setDeleteDialogOpen(true)
  }
  const deleteDialogClose = () => {
    setDeleteDialogOpen(false)
  }

  const deleteSongFromList = async () => {
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/songlists/delsong`,
      {
        songlist_id: songlist_id,
        song_id: songId
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.token}`
        }
      }
    )
    if ('status' in response.data) {
      if (response.data.status === 'successfully') {
        enqueueSnackbar('Laulu poistettu onnistuneesti listalta!', {
          variant: 'success'
        })
        getSonglist()
        setDeleteDialogOpen(false)
        return
      }
    }
    enqueueSnackbar(`Poisto epäonnistui :(`, {
      variant: 'error'
    })
    setDeleteDialogOpen(false)
  }

  return (
    <div className="song-list">
      {width > 600 ? (
        <TableFormat
          songs={songs}
          listmode={listmode}
          handleDelete={handleDelete}
          setScrollPosition={setScrollPosition}
        />
      ) : (
        <StackFormat
          songs={songs}
          listmode={listmode}
          handleDelete={handleDelete}
          setScrollPosition={setScrollPosition}
        />
      )}

      <DeleteDialog
        open={deleteDialogOpen}
        handleClose={deleteDialogClose}
        deleteItem={deleteSongFromList}
        msg={'Oletko varma, että haluat poistaa laulun?'}
      />
    </div>
  )
}

SongList.propTypes = {
  songs: PropTypes.array,
  listmode: PropTypes.bool,
  songlist_id: PropTypes.any,
  getSonglist: PropTypes.func
}

export default SongList
