import React from 'react'
import { useParams } from 'react-router'
import { Card, CardContent, Box } from '@mui/material'

import SongLyric from './SongLyric'

import './style.scss'

function Song() {
  const { id } = useParams()

  return (
    <Box className="song content">
      <Card className="card">
        <CardContent className="card-content">
          <SongLyric id={id} />
        </CardContent>
      </Card>
    </Box>
  )
}

export default Song
