import React from 'react'
import PropTypes from 'prop-types'

import './style.scss'

function Link(props) {
  const { clickFunction, linkText } = props
  return (
    <span className="link-style" onClick={clickFunction}>
      {linkText}
    </span>
  )
}

Link.propTypes = {
  clickFunction: PropTypes.func,
  linkText: PropTypes.string
}

export default Link
