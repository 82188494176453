import React from 'react'
import PropTypes from 'prop-types'

import './style.scss'

function FancyTitle(props) {
  const { func, text, size } = props
  return (
    <div className="fancy-title" onClick={func} style={{ 'font-size': size }}>
      {text}
    </div>
  )
}

FancyTitle.propTypes = {
  func: PropTypes.func,
  text: PropTypes.string,
  size: PropTypes.string
}

export default FancyTitle
