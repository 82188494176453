import React from 'react'
import PropTypes from 'prop-types'

import { Tooltip } from '@mui/material'

import { PlaylistRemove } from '@mui/icons-material'

import { useNavigate } from 'react-router-dom'

function Item(props) {
  const navigate = useNavigate()

  const { index, song, listmode, handleDelete, setScrollPosition } = props

  const handleClick = (url, type) => {
    setScrollPosition()
    if (type === 'local') {
      navigate(url)
    } else {
      window.open(url, '_blank')
    }
  }

  const cutString = (str, len) => {
    if (str) {
      if (str.length > len) return `${str.substring(0, len)}...`
    }

    return str
  }

  return (
    <div className="item">
      <div
        className="body"
        style={{
          width: '100%'
        }}
      >
        <b className="index-num">{index}</b>
        <div className="name" onClick={() => handleClick(song.url, song.type)}>
          {cutString(song.title, 28)}
        </div>
        <div className="key">{song.key_name}</div>
        <div>
          <div className="artist">{cutString(song.artist, 25)}</div>
          <div className="category">{song.category}</div>
        </div>
      </div>

      {listmode && (
        <div className="buttons">
          <Tooltip title="Poista listalta">
            <button
              className="btn action-btn"
              onClick={() => handleDelete(song.id)}
            >
              <PlaylistRemove className="icon" />
            </button>
          </Tooltip>
        </div>
      )}
    </div>
  )
}

Item.propTypes = {
  index: PropTypes.number,
  song: PropTypes.object,
  listmode: PropTypes.bool,
  handleDelete: PropTypes.func,
  setScrollPosition: PropTypes.func
}

export default Item
