import { Card, CardContent, LinearProgress } from '@mui/material'
import { useParams } from 'react-router'
import React, { useEffect, useState, useContext } from 'react'
import axios from 'axios'
// components
import SongList from '../../components/SongList'

import './Styles.css'

import { AuthContext } from '../../context/auth-context'

function Songs() {
  const auth = useContext(AuthContext)
  const { id } = useParams()
  let [song_list_name, setSongListName] = useState('')
  let [songs, setSongs] = useState([])
  let [loading, setLoading] = useState(true)

  useEffect(() => {
    if (Number(id) === 0) {
      setSongListName('')
      setSongs([])
      getSongs()
    } else getSonglist()
  }, [id])

  const getSongs = async () => {
    const resp = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/songs`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth.token}`
      }
    })
    if (!resp) return
    const formated = resp.data.map((item) => ({
      title: item.title,
      id: item.id,
      type: 'local',
      url: `/song/${item.id}`,
      artist: item.artist,
      tab_type: 'chord',
      key_name: item.key_name,
      category: item.category,
      youtube_link: item.youtube_link
    }))
    setSongs(formated)
  }

  const getSonglist = async () => {
    const respID = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/songlists`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.token}`
        }
      }
    )
    if (!respID) return

    let ids = []
    let songlist = []

    respID.data.forEach((item) => {
      if (Number(item.id) === Number(id)) {
        ids = JSON.parse(item.songs)
        setSongListName(item.name)
      }
    })

    if (!ids || ids.length === 0) {
      setLoading(false)
      return
    }

    const respSongs = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/songs`,
      {
        ids: ids
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.token}`
        }
      }
    )
    if (!respSongs) return
    songlist = respSongs.data

    // console.log(respSongs.data);

    const formated = songlist.map((item) => ({
      title: item.title,
      id: item.id,
      type: 'local',
      url: `/song/${item.id}`,
      artist: item.artist,
      tab_type: 'chord',
      key_name: item.key_name,
      category: item.category,
      youtube_link: item.youtube_link
    }))
    setSongs(formated)
  }

  return (
    <div className="songs content">
      <Card>
        {songs.length < 1 && loading ? (
          <LinearProgress className="progbar" />
        ) : (
          ''
        )}
        <CardContent>
          {song_list_name && <h3>{song_list_name}</h3>}
          <SongList
            songs={songs}
            showmode="local"
            listmode={song_list_name ? true : false}
            songlist_id={id}
            getSonglist={getSonglist}
          />
        </CardContent>
      </Card>
    </div>
  )
}

export default Songs
