import React, { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import {
  DeleteOutlined,
  EditOutlined,
  AddOutlined,
  Print
} from '@mui/icons-material'
import Parser from 'html-react-parser'
import { useNavigate } from 'react-router-dom'
import { Tooltip } from '@mui/material'
import { useSnackbar } from 'notistack'
import axios from 'axios'
import * as Transposer from 'chord-transposer'
import AddToListDialog from '../../components/AddToListDialog'
import InputDialog from '../../components/InputDialog'
import DeleteDialog from '../../components/DeleteDialog'
import { keys, suffixes, keys_major, keys_minor } from './Chords'
import { Box, Stack } from '@mui/material'

import { AuthContext } from '../../context/auth-context'

function SongLyric(props) {
  const auth = useContext(AuthContext)
  const { id } = props

  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()

  // Confirm dialog
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const confirmDeleteSong = () => {
    setDeleteDialogOpen(true)
  }
  const deleteDialogClose = () => {
    setDeleteDialogOpen(false)
  }
  // Input dialog
  const [inputDialogOpen, setInputDialogOpen] = useState(false)
  const openInputDialog = () => {
    setInputDialogOpen(true)
  }

  // Data
  const [songListData, setSongListData] = useState([])
  const [songData, setSongData] = useState({
    id: 0,
    title: '',
    lyric: '',
    notes: [],
    artist: '',
    key_name: '',
    users_key: '',
    category: '',
    youtube_link: ''
  })
  const [newKey, setNewKey] = useState('')

  // Snacks
  const deleteSnack = () => {
    enqueueSnackbar('Laulu poistettu onnistuneesti!', {
      variant: 'success'
    })
  }

  const unauthorizedSnack = () => {
    enqueueSnackbar(
      'Ei oikeutta poistaa laulua, voit poistaa vain itse lisäämiäsi lauluja',
      {
        variant: 'error'
      }
    )
  }

  const saveSnack = () => {
    enqueueSnackbar('Laulu tallennettu onnistuneesti!', {
      variant: 'success'
    })
  }

  const errorSnack = (error) => {
    if (error) {
      enqueueSnackbar(`${error}`, {
        variant: 'error'
      })
    } else {
      enqueueSnackbar(`Tapahtui virhe :(`, {
        variant: 'error'
      })
    }
  }

  // get songlist
  useEffect(() => {
    getSonglists()
  }, [])

  const getSonglists = async () => {
    const resp = await axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/songlists`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.token}`
        }
      })
      .catch((error) => {
        errorSnack(error)
      })
    if (resp) {
      setSongListData(resp.data)
    }
  }

  // Add song to list Dialog
  const [addSongDialogOpen, setAddSongDialogOpen] = useState(false)

  const handleAddSongDialogkOpen = () => {
    setAddSongDialogOpen(true)
  }
  const handleAddSongDialogClose = (resp) => {
    setAddSongDialogOpen(false)
    if ('state' in resp) {
      if (resp.state) openInputDialog()
      else updateSongList(resp.id)
    }
  }

  useEffect(() => {
    const getSong = async () => {
      if (!id) return
      let response = await axios
        .get(`${process.env.REACT_APP_BACKEND_URL}/songs/${id}`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${auth.token}`
          }
        })
        .catch((error) => {
          errorSnack(error)
        })
      if (!response) return

      if (response.data.notes) {
        response.data.notes = JSON.parse(response.data.notes)
      }

      let new_key = response.data.users_key
      response.data.users_key = response.data.key_name

      setSongData(response.data)
      setNewKey(new_key)
    }
    getSong()
  }, [id])

  useEffect(() => {
    if (newKey) transposeLyric()
  }, [newKey])

  const saveKey = async () => {
    if (!id || !newKey) return
    const response = await axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/songs/update/key`,
        {
          id: id,
          users_key: newKey
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${auth.token}`
          }
        }
      )
      .catch(() => {
        errorSnack('Sävellajin muutos epäonnistui')
      })
    if ('status' in response.data) {
      if (response.data.status !== 'successfully') {
        errorSnack('Sävellajin muutos epäonnistui')
      }
    }
  }

  const transposeLyric = () => {
    const currentKey = songData.users_key

    let key_list = keys_major
    if (currentKey.includes('m')) {
      key_list = keys_minor
    }

    console.log(`transpose ${currentKey} -> ${newKey}`)

    let keyI = key_list.findIndex((value) => value === newKey)
    let currentI = key_list.findIndex((value) => value === currentKey)
    try {
      if (keyI < currentI) {
        setSongData({
          ...songData,
          lyric: Transposer.transpose(songData.lyric)
            .down(currentI - keyI)
            .toString(),
          users_key: newKey
        })
      } else {
        setSongData({
          ...songData,
          lyric: Transposer.transpose(songData.lyric)
            .up(keyI - currentI)
            .toString(),
          users_key: newKey
        })
      }
    } catch (err) {
      console.log("Cant't transpose: " + err.message)
    }

    saveKey()
  }

  const deleteSong = async () => {
    if (!id) return
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/songs/delete`,
      {
        id: id
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.token}`
        }
      }
    )
    if ('status' in response.data) {
      if (response.data.status === 'successfully') {
        deleteSnack()
        navigate(`/`)
      }
      if (response.data.status === 'unauthorized') {
        unauthorizedSnack()
        deleteDialogClose()
      }
    }
  }

  const editSong = () => {
    navigate(`/editor/${id}`)
  }

  const transposeBtns = () => {
    if (!songData.users_key) return

    let key_list = keys_major
    if (songData.users_key.includes('m')) {
      key_list = keys_minor
    }
    return (
      <div className="transpose">
        {key_list.map((key, index) => (
          <button
            key={index}
            className={
              songData.users_key === key
                ? 'btn key the-key noselect'
                : 'btn key noselect'
            }
            onClick={(e) => setNewKey(e.target.innerText)}
          >
            {key}
          </button>
        ))}
        <div className="default-key">Key: {songData.key_name}</div>
      </div>
    )
  }

  const createNewList = async (listname) => {
    let songs = []
    songs.push(songData.id)
    // let song_data = JSON.parse(JSON.stringify(songData));
    // delete song_data["lyric"];
    // songs.push(song_data);

    const resp = await axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/songlists/add`,
        {
          listname: listname,
          songlist: songs
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${auth.token}`
          }
        }
      )
      .catch((error) => {
        errorSnack(error)
      })

    if (!resp) return
    if ('status' in resp.data) {
      if (resp.data.status === 'successfully') {
        saveSnack()
        getSonglists()
        return
      }
    }
    errorSnack('')
  }

  const updateSongList = async (songlist_id) => {
    if (songlist_id) {
      let new_songlist = []
      if (!songListData) return
      songListData.forEach((item) => {
        if (item.id === songlist_id) {
          item.songs = JSON.parse(item.songs)
          new_songlist = item
        }
      })
      new_songlist.songs.push(songData.id)
      const resp = await axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/songlists/update`,
          {
            id: songlist_id,
            // listname: new_songlist.listname,
            songlist: new_songlist.songs
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${auth.token}`
            }
          }
        )
        .catch((error) => {
          errorSnack(error)
        })
      if (!resp) return
      if ('status' in resp.data) {
        if (resp.data.status === 'successfully') {
          saveSnack()
          getSonglists()
          return
        }
      }
      errorSnack('')
    }
  }

  const printSong = () => {
    var prtContent1 = document.getElementById('song-title')
    var prtContent2 = document.getElementById('song-lyric')
    var WinPrint = window.open(
      '',
      '',
      'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0'
    )
    WinPrint.document.write(prtContent1.innerHTML)
    WinPrint.document.write(prtContent2.innerHTML)
    WinPrint.document.close()
    WinPrint.focus()
    WinPrint.print()
    WinPrint.close()
  }

  const actionBtns = () => {
    return (
      <div className="actions">
        <Tooltip title="Tulosta laulu">
          <button className="btn action-btn" onClick={printSong}>
            <Print className="icon" />
          </button>
        </Tooltip>

        <Tooltip title="Lisää laululistaan">
          <button className="btn action-btn" onClick={handleAddSongDialogkOpen}>
            <AddOutlined className="icon" />
          </button>
        </Tooltip>

        <Tooltip title="Muokkaa laulua">
          <button
            variant="contained"
            color="secondary"
            size="small"
            className="btn action-btn"
            onClick={editSong}
          >
            <EditOutlined />
          </button>
        </Tooltip>

        <Tooltip title="Poista laulu">
          <button className="btn action-btn" onClick={confirmDeleteSong}>
            <DeleteOutlined className="icon" />
          </button>
        </Tooltip>
      </div>
    )
  }

  const generateLyrics = () => {
    if (!songData.lyric) return ''
    let mod_lyric = songData.lyric
      .replaceAll('[', '<b>')
      .replaceAll(']', '</b>')
    // mod_lyric = `<span>${mod_lyric.split("\n").join("</span><span>")}</span>`;

    let lyricc = mod_lyric.split('\n').map((row) => {
      row = row.replace(/(\r\n|\n|\r)/gm, '')
      let new_row = `${row}\n`
      let match = 0

      keys.forEach((key_item) => {
        row.split(' ').forEach((item) => {
          if (item === key_item) match++
          suffixes.forEach((suffix) => {
            if (item === `${key_item}${suffix}`) match++
          })
        })
      })

      if (match >= 1) {
        new_row = `<span className="chords">${row}</span>\n`
      }

      return new_row
    })

    return lyricc.join('')
  }

  const generateNotes = () => {
    if (!songData.notes) return
    return (
      <div>
        {songData.notes.map((item, index) => (
          <img
            src={item}
            key={index}
            alt={`notes_${index}`}
            className="notes"
          />
        ))}
      </div>
    )
  }

  return (
    <Stack>
      <Box className="header">
        {actionBtns()}
        <Box id="song-title">
          <Box className="custom-title">{songData.title}</Box>
          <Box sx={{ fontSize: 20, fontStyle: 'italic' }}>
            {songData.category}{' '}
            {songData.youtube_link && (
              <a href={songData.youtube_link} target="_blank" rel="noreferrer">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="red"
                  className="bi bi-youtube"
                  viewBox="0 0 16 16"
                >
                  <path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.007 2.007 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.007 2.007 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31.4 31.4 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.007 2.007 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A99.788 99.788 0 0 1 7.858 2h.193zM6.4 5.209v4.818l4.157-2.408L6.4 5.209z" />
                </svg>
              </a>
            )}
          </Box>
        </Box>
      </Box>
      <Box>{transposeBtns()}</Box>
      <Box id="song-lyric">
        <pre className="lyric">
          {generateNotes()}
          {Parser(generateLyrics())}
        </pre>
        {songData.artist ? (
          <p className="artist">Artist: {songData.artist}</p>
        ) : (
          ''
        )}
      </Box>

      <DeleteDialog
        open={deleteDialogOpen}
        handleClose={deleteDialogClose}
        deleteItem={deleteSong}
        msg={'Tämä laulu poistetaan pysyvästi, etkä voi palauttaa sitä!'}
      />
      <AddToListDialog
        open={addSongDialogOpen}
        onClose={handleAddSongDialogClose}
        listvalues={songListData}
      />
      <InputDialog
        open={inputDialogOpen}
        setOpen={setInputDialogOpen}
        doWithInputValue={createNewList}
      />
    </Stack>
  )
}

SongLyric.propTypes = {
  id: PropTypes.string
}

export default SongLyric
