import { Card, CardContent } from '@mui/material'
import React, { useEffect, useState, useContext } from 'react'
import axios from 'axios'
// components
import SongList from './List'
import { useSnackbar } from 'notistack'

import './Styles.css'

import { AuthContext } from '../../context/auth-context'

function SongsLists() {
  const auth = useContext(AuthContext)
  const { enqueueSnackbar } = useSnackbar()
  let [songlists, setSongLists] = useState([])

  useEffect(() => {
    getSonglists()
  }, [])

  const getSonglists = async () => {
    // console.log(process.env.REACT_APP_BACKEND_URL)
    const resp = await axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/songlists`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.token}`
        }
      })
      .catch((error) => {
        errorSnack(error)
      })
    if (resp) {
      setSongLists(resp.data)
    }
  }

  // Error snack
  const errorSnack = (error) => {
    if (error) {
      enqueueSnackbar(`${error}`, {
        variant: 'error'
      })
    } else {
      enqueueSnackbar(`Tapahtui virhe :(`, {
        variant: 'error'
      })
    }
  }

  return (
    <div className='songlists content'>
      <Card>
        <CardContent>
          <SongList songlists={songlists} getSonglists={getSonglists} />
        </CardContent>
      </Card>
    </div>
  )
}

export default SongsLists
