import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import React, { useState } from 'react'
import PropTypes from 'prop-types'

FormDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  doWithInputValue: PropTypes.func
}

export default function FormDialog(props) {
  const { open, setOpen, doWithInputValue } = props
  const [inputValue, setInputValue] = useState('')

  const handleClose = () => {
    setOpen(false)
  }

  const handleSubmit = () => {
    doWithInputValue(inputValue)
    setOpen(false)
  }

  const inputChanged = (e) => {
    setInputValue(e.target.value)
  }

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        {/* <DialogTitle>Name</DialogTitle> */}
        <DialogContent>
          <DialogContentText>Give name for song list</DialogContentText>
          <TextField
            autoFocus
            margin='dense'
            id='name'
            label='Song list'
            type='email'
            fullWidth
            variant='standard'
            onChange={inputChanged}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit}>Submit</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
