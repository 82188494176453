import React from 'react'
import { Card, CardContent } from '@mui/material'
import { useParams } from 'react-router'

// components
import EditorLyric from './EditorLyric'

// styles
import './Styles.css'

function Editor() {
  const { id } = useParams()

  return (
    <div className='content editor'>
      <Card className='card'>
        <CardContent>
          <EditorLyric id={id} />
        </CardContent>
      </Card>
    </div>
  )
}

export default Editor
