import React from 'react'
import Avatar from '@mui/material/Avatar'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ListItemText from '@mui/material/ListItemText'
import DialogTitle from '@mui/material/DialogTitle'
import Dialog from '@mui/material/Dialog'
import AddIcon from '@mui/icons-material/Add'
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd'
import { blue } from '@mui/material/colors'
import PropTypes from 'prop-types'

function AddToListDialog(props) {
  const { onClose, open, listvalues } = props

  const handleClose = () => {
    onClose({})
  }

  const handleListItemClick = (value, state) => {
    onClose({ state: state, id: value })
  }

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>Add song to list</DialogTitle>
      <List sx={{ pt: 0 }}>
        {listvalues.map((item) => (
          <ListItem
            button
            onClick={() => handleListItemClick(item.id, false)}
            key={item.id}
          >
            <ListItemAvatar>
              <Avatar sx={{ bgcolor: blue[100], color: blue[600] }}>
                <PlaylistAddIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={item.name} />
          </ListItem>
        ))}

        <ListItem
          autoFocus
          button
          onClick={() => handleListItemClick('', true)}
        >
          <ListItemAvatar>
            <Avatar>
              <AddIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary='Create new list' />
        </ListItem>
      </List>
    </Dialog>
  )
}

AddToListDialog.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.func,
  listvalues: PropTypes.array
}

export default AddToListDialog
