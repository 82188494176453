import React, { useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import { NavLink } from 'react-router-dom'

import MenuIcon from '@mui/icons-material/Menu'
import {
  Typography,
  Menu,
  Container,
  Button,
  MenuItem,
  AppBar,
  Toolbar,
  Box,
  IconButton,
  Tooltip
} from '@mui/material'

import { PersonOutlineOutlined } from '@mui/icons-material'

import FancyTitle from '../shared/FancyTitle'
import { AuthContext } from '../../context/auth-context'

import './style.css'
// import logo from '../../assets/logo.png'

const PAGE_TITLE = <FancyTitle text="CCB" size="40px" />
const TEXT_COLOR = '#403d39'

const pages = [
  { name: 'Hae', path: '/', role: [1, 2] },
  { name: 'Laululistat', path: '/songlists', role: [1, 2] },
  { name: 'Laulut', path: '/songs/0', role: [1, 2] },
  { name: 'Lisää uusi', path: '/editor/0', role: [1, 2] }
]

function NavbarElement() {
  const [mobileMenu, setMobileMenu] = useState(null)
  const [profileMenu, setProfileMenu] = useState(null)
  const auth = useContext(AuthContext)

  const handleOpenMobileMenu = (event) => {
    setMobileMenu(event.currentTarget)
  }

  const handleCloseMobileMenu = () => {
    setMobileMenu(null)
  }

  return (
    <div>
      <AppBar position="static" sx={{ bgcolor: '#ffffff', color: TEXT_COLOR }}>
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            {/* <img src={logo} alt='Logo' className='logo' /> */}

            {/* DESKTOP TITLE */}
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ mr: 2, display: { xs: 'none', md: 'flex' } }}
            >
              {PAGE_TITLE}
            </Typography>

            {/* DESKTOP MENU */}
            {auth.isLoggedIn && (
              <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                {pages.map((page, index) => {
                  if (page.role.includes(auth.role))
                    return (
                      <Button
                        key={index}
                        onClick={handleCloseMobileMenu}
                        sx={{ my: 2, color: TEXT_COLOR, display: 'block' }}
                        as={Link}
                        to={page.path}
                        className="menu-link"
                      >
                        <Typography textAlign="center">{page.name}</Typography>
                      </Button>
                    )
                })}
              </Box>
            )}

            {/* MOBILE MENU */}
            {auth.isLoggedIn && (
              <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenMobileMenu}
                  color="inherit"
                >
                  <MenuIcon />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={mobileMenu}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                  open={Boolean(mobileMenu)}
                  onClose={handleCloseMobileMenu}
                  sx={{
                    display: { xs: 'block', md: 'none' }
                  }}
                >
                  {pages.map((page, index) => {
                    if (page.role.includes(auth.role))
                      return (
                        <MenuItem key={index} onClick={handleCloseMobileMenu}>
                          <Typography
                            textAlign="center"
                            as={Link}
                            to={page.path}
                            className="mobile-menu-link"
                          >
                            {page.name}
                          </Typography>
                        </MenuItem>
                      )
                  })}
                </Menu>
              </Box>
            )}

            {/* MOBILE TITLE */}
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}
            >
              {PAGE_TITLE}
            </Typography>

            {/* PROFILE AND LOGOUT */}
            {auth.isLoggedIn && (
              <Box sx={{ flexGrow: 0, mr: 3 }}>
                <Tooltip title="Open settings">
                  <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="user-appbar"
                    aria-haspopup="true"
                    color="inherit"
                    onClick={(e) => setProfileMenu(e.currentTarget)}
                  >
                    <PersonOutlineOutlined />
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: '45px' }}
                  id="menu-appbar"
                  anchorEl={profileMenu}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                  }}
                  open={Boolean(profileMenu)}
                  onClose={() => setProfileMenu(null)}
                >
                  <MenuItem
                    onClick={() => setProfileMenu(null)}
                    component={NavLink}
                    to="/profile"
                  >
                    <Typography textAlign="center">Profiili</Typography>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setProfileMenu(null)
                      auth.logout()
                    }}
                  >
                    <Typography textAlign="center">Kirjaudu ulos</Typography>
                  </MenuItem>
                </Menu>
              </Box>
            )}
          </Toolbar>
        </Container>
      </AppBar>
    </div>
  )
}

export default NavbarElement
