import React, { useState } from 'react'

// components
import Search from './Search'
import SongList from '../../components/SongList'
import Divider from '@mui/material/Divider'

// styles
import './style.scss'

function Home() {
  const [songs, setSongs] = useState([])

  const updateSongs = (data) => {
    setSongs(data)
  }

  return (
    <div className="content">
      <div className="home">
        <div className="search-wrapper">
          <Search updateSongs={updateSongs} />
        </div>

        <Divider />

        {songs.length > 0 && songs !== 'clean' && (
          <div className="songs">
            <SongList songs={songs} />
          </div>
        )}
      </div>
    </div>
  )
}

export default Home
