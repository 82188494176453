import React from 'react'
import PropTypes from 'prop-types'

import { Stack } from '@mui/material'

import Item from './Item'

function StackFormat(props) {
  const { songs, listmode, handleDelete, setScrollPosition } = props

  return (
    <div className="stack-format">
      <Stack>
        {songs.map((song, index) => (
          <Item
            key={index}
            index={index + 1}
            song={song}
            listmode={listmode}
            handleDelete={handleDelete}
            setScrollPosition={setScrollPosition}
          />
        ))}
      </Stack>
    </div>
  )
}

StackFormat.propTypes = {
  songs: PropTypes.array,
  listmode: PropTypes.bool,
  handleDelete: PropTypes.func,
  setScrollPosition: PropTypes.func
}

export default StackFormat
