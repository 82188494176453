import './styles/App.css'

import React, { useState, useCallback, useEffect } from 'react'
import {
  Route,
  Routes,
  BrowserRouter as Router,
  Navigate
} from 'react-router-dom'
import { SnackbarProvider } from 'notistack'

import { AuthContext } from './context/auth-context'

import 'bootstrap/dist/css/bootstrap.min.css'

// components
import Authenticate from './views/Authenticate'
import Profile from './views/Profile'
import Home from './views/Home/'
import Editor from './views/Editor/'
import SongLists from './views/SongLists/'
import Songs from './views/Songs/'
import Song from './views/Song/'
import NavBar from './components/Navbar'

let logoutTimer

function App() {
  const [token, setToken] = useState(false)
  const [tokenExpirationDate, setTokenExpirationDate] = useState()
  const [userId, setUserId] = useState(false)
  const [role, setRole] = useState(false)

  const login = useCallback((uid, token, role, expirationDate) => {
    // prevent a render loop
    setToken(token)
    setUserId(uid)
    setRole(role)
    // current date in ms + 1h
    const tokenExpirationDate =
      expirationDate || new Date(new Date().getTime() + 6000 * 60 * 60)
    setTokenExpirationDate(tokenExpirationDate)
    localStorage.setItem(
      'userData',
      JSON.stringify({
        userId: uid,
        token,
        role,
        expiration: tokenExpirationDate.toISOString()
      })
    )
  }, [])

  const logout = useCallback(() => {
    // prevent a render loop
    setToken(null)
    setUserId(null)
    setRole(null)
    setTokenExpirationDate(null)
    localStorage.removeItem('userData')
  }, [])

  useEffect(() => {
    if (token && tokenExpirationDate) {
      const remainingTime = tokenExpirationDate.getTime() - new Date().getTime()
      logoutTimer = setTimeout(logout, remainingTime)
    } else {
      clearTimeout(logoutTimer)
    }
  }, [token, logout, tokenExpirationDate])

  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem('userData'))
    if (
      storedData &&
      storedData.token &&
      new Date(storedData.expiration) > new Date() // if greater, still in future
    ) {
      login(
        storedData.userId,
        storedData.token,
        storedData.role,
        new Date(storedData.expiration)
      )
    }
  }, [login])

  const generateRoutes = () => {
    if (token) {
      return (
        <>
          <NavBar />
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route path="/editor/:id" element={<Editor />} />
            <Route path="/songlists" element={<SongLists />} />
            <Route path="/songs/:id" element={<Songs />} />
            <Route path="/song/:id" element={<Song />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="*" element={<Navigate replace to="/" />} />
          </Routes>
        </>
      )
    }
    return (
      <Routes>
        <Route path="/login" element={<Authenticate />} />
        <Route path="*" element={<Authenticate />} />
        {/* <Route path="*" element={<Navigate replace to="/login" />} /> */}
      </Routes>
    )
  }

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: !!token,
        token,
        userId,
        role,
        login,
        logout
      }}
    >
      <main className="App">
        {/* {routes} */}
        <Router>
          <SnackbarProvider maxSnack={3}>{generateRoutes()}</SnackbarProvider>
        </Router>
      </main>
    </AuthContext.Provider>
  )
}

export default App
