const keys = [
  "C",
  "C#",
  "Db",
  "D",
  "D#",
  "Eb",
  "E",
  "F",
  "F#",
  "Gb",
  "G",
  "G#",
  "Ab",
  "A",
  "A#",
  "Bb",
  "B",
];

const suffixes = [
  "m",
  "dim",
  "dim7",
  "sus2",
  "sus4",
  "sus2sus4",
  "7sus4",
  "7/G",
  "alt",
  "aug",
  "5",
  "6",
  "69",
  "7",
  "7b5",
  "aug7",
  "9",
  "9b5",
  "aug9",
  "7b9",
  "7#9",
  "11",
  "9#11",
  "13",
  "maj7",
  "maj7b5",
  "maj7#5",
  "maj9",
  "maj11",
  "maj13",
  "m6",
  "m69",
  "m7",
  "m7b5",
  "m9",
  "m11",
  "mmaj7",
  "mmaj7b5",
  "mmaj9",
  "mmaj11",
  "add9",
  "madd9",
  "/E",
  "/F",
  "/F#",
  "/G",
  "/G#",
  "/A",
  "/Bb",
  "/B",
  "/C",
  "/C#",
  "m/B",
  "m/C",
  "m/C#",
  "/D",
  "m/D",
  "/D#",
  "m/D#",
  "m/E",
  "m/F",
  "m/F#",
  "m/G",
  "m/G#",
];

const keys_major = [
  "C",
  "Db",
  "D",
  "Eb",
  "E",
  "F",
  "Gb",
  "G",
  "Ab",
  "A",
  "Bb",
  "B",
];

const keys_minor = [
  "Cm",
  "C#m",
  "Dm",
  "Ebm",
  "Em",
  "Fm",
  "F#m",
  "Gm",
  "G#m",
  "Am",
  "Bbm",
  "Bm",
];

export { keys, suffixes, keys_major, keys_minor };
