import { TextField, LinearProgress } from '@mui/material'
import { useState, useEffect, useContext, useRef } from 'react'
import { useSearchParams } from 'react-router-dom'
import PropTypes from 'prop-types'
import React from 'react'
import axios from 'axios'

import { AuthContext } from '../../context/auth-context'

function Search(props) {
  const [searchParams, setSearchParams] = useSearchParams()
  const auth = useContext(AuthContext)
  const ref = useRef(true)
  const { updateSongs } = props

  const [query, setQuery] = useState('')
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const query = searchParams.get('q') || ''
    setQuery(query)
  }, [searchParams])

  useEffect(() => {
    const timeOutId = setTimeout(() => searchSongs(), 500)
    return () => clearTimeout(timeOutId)
  }, [query])

  const searchSongs = async () => {
    const firstRender = ref.current

    if (firstRender) {
      ref.current = false
      // console.log('First Render')
    } else {
      setSearchParams(`q=${query}`)
    }

    updateSongs('clean')

    if (query.length < 3) {
      return
    }

    setLoading(true)

    const all_songs = []

    const resp = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/songs/search/${query}`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.token}`
        }
      }
    )

    const local_songs = resp.data.map((item) => ({
      title: item.title,
      id: item.id,
      type: 'local',
      url: `/song/${item.id}`,
      artist: item.artist,
      tab_type: 'chord',
      key_name: item.key_name,
      category: item.category,
      youtube_link: item.youtube_link
    }))
    all_songs.push.apply(all_songs, local_songs)

    // all_songs = all_songs.sort((a, b) => a.title - b.title);

    if (all_songs && all_songs.length > 0) {
      updateSongs(all_songs)
    }
    setLoading(false)
  }

  return (
    <div className="search">
      {/* <TextField label="Song" onChange={searchSongs} /> */}
      <TextField
        className="input"
        label="Hae laulua..."
        type="text"
        value={query}
        onChange={(event) => setQuery(event.target.value)}
        variant="standard"
      />
      {loading ? <LinearProgress className="progbar" /> : ''}
    </div>
  )
}

Search.propTypes = {
  updateSongs: PropTypes.func
}

export default Search
