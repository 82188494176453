import React, { useContext, useState, useEffect } from 'react'
import { useSnackbar } from 'notistack'
import axios from 'axios'
import {
  Container,
  Card,
  Button,
  TextField,
  Stack,
  CardContent
} from '@mui/material'

import { AuthContext } from '../../context/auth-context'

import './Profile.css'

const App = () => {
  const auth = useContext(AuthContext)
  const { enqueueSnackbar } = useSnackbar()

  // const user_template = {
  //   name: '',
  //   old_password: '',
  //   password: '',
  //   password2: ''
  // }

  const [userData, setUserdata] = useState({})

  useEffect(() => {
    getUser()
  }, [])

  const getUser = async () => {
    if (!auth.token) return
    const data = await axios.get(
      process.env.REACT_APP_BACKEND_URL + '/users/details',
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.token}`
        }
      }
    )
    setUserdata(data.data)
  }

  const updateUser = async () => {
    if (!auth.token) return

    if (userData.password !== userData.password2) {
      enqueueSnackbar('Salasanat eivät täsmää!', {
        variant: 'error'
      })
      return
    }

    axios
      .patch(process.env.REACT_APP_BACKEND_URL + '/users/details', userData, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.token}`
        }
      })
      .then((resp) => {
        if ('status' in resp.data) {
          if (resp.data.status === 'ok') {
            getUser()
            auth.logout()
            enqueueSnackbar('Käyttäjätietojen muokkaus onnistui', {
              variant: 'success'
            })
          } else {
            enqueueSnackbar('Tapahtui virhe :(', {
              variant: 'error'
            })
          }
        }
      })
      .catch((err) => {
        if (err.response) {
          enqueueSnackbar(err.response.data.msg, {
            variant: 'error'
          })
        } else {
          enqueueSnackbar('Tapahtui virhe :(', {
            variant: 'error'
          })
        }
      })
  }

  return (
    <Container fixed>
      <Card sx={{ mt: 2, mb: 2, padding: 2 }}>
        <CardContent>
          <h2>Profiili</h2>
          <Stack spacing={2}>
            <TextField
              className='text-input'
              label='Nimi'
              variant='outlined'
              value={userData.name || ''}
              onChange={(e) => {
                setUserdata({
                  ...userData,
                  name: e.target.value
                })
              }}
            />
            <TextField
              className='text-input'
              type='password'
              label='Vanha salasana'
              variant='outlined'
              value={userData.old_password || ''}
              onChange={(e) => {
                setUserdata({
                  ...userData,
                  old_password: e.target.value
                })
              }}
            />
            <TextField
              className='text-input'
              type='password'
              label='Uusi salasana'
              variant='outlined'
              value={userData.password || ''}
              onChange={(e) => {
                setUserdata({
                  ...userData,
                  password: e.target.value
                })
              }}
            />
            <TextField
              className='text-input'
              type='password'
              label='Uusi salasana uudelleen'
              variant='outlined'
              value={userData.password2 || ''}
              onChange={(e) => {
                setUserdata({
                  ...userData,
                  password2: e.target.value
                })
              }}
            />
            <Button
              className='button-input'
              variant='outlined'
              onClick={updateUser}
            >
              Päivitä käyttäjätiedot
            </Button>
          </Stack>
        </CardContent>
      </Card>
    </Container>
  )
}

export default App
