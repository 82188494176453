import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tooltip
} from '@mui/material'
import { DeleteOutlined } from '@mui/icons-material'

function TableFormat(props) {
  const { songs, listmode, handleDelete, setScrollPosition } = props

  const generateLink = (type, url, title) => {
    if (type === 'local') {
      return (
        <Link to={url} onClick={setScrollPosition}>
          {title}
        </Link>
      )
    } else {
      return (
        <a
          href={url}
          onClick={setScrollPosition}
          target="_blank"
          rel="noreferrer"
        >
          {title}
        </a>
      )
    }
  }

  const generateRows = () => {
    if (songs === 'clean') return
    if (songs) {
      return songs.map((row, index) => (
        <TableRow
          className="song-name"
          url={row.url}
          type={row.type}
          key={index}
          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        >
          {listmode && <TableCell>{index + 1}</TableCell>}
          <TableCell className="noselect" component="th" scope="row">
            {generateLink(row.type, row.url, row.title)}
          </TableCell>
          <TableCell align="left">{row.category}</TableCell>
          <TableCell align="left">{row.artist}</TableCell>
          <TableCell align="left">{row.key_name}</TableCell>

          {listmode && (
            <TableCell align="center">
              <Tooltip title="Poista laulu">
                <button
                  className="btn action-btn"
                  onClick={() => handleDelete(row.id)}
                >
                  <DeleteOutlined className="icon" />
                </button>
              </Tooltip>
            </TableCell>
          )}
        </TableRow>
      ))
    }
  }

  return (
    <div className="table-format">
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small">
          <TableHead>
            <TableRow>
              {listmode && <TableCell>#</TableCell>}
              <TableCell>Nimi</TableCell>
              <TableCell align="left">Tyyppi</TableCell>
              <TableCell align="left">Tekijä(t)</TableCell>
              <TableCell align="left">Sävellaji</TableCell>
              {listmode && <TableCell align="center">Toiminnat</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>{generateRows()}</TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

TableFormat.propTypes = {
  songs: PropTypes.array,
  listmode: PropTypes.bool,
  handleDownload: PropTypes.func,
  handleDelete: PropTypes.func,
  setScrollPosition: PropTypes.func
}

export default TableFormat
